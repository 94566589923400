.abilitiesPage{
    text-align: center;
    display: flex;
    justify-content: center !important;
}

.abilitiesPage .col-md-6 .card{
    padding: 10px;
    height: 100% !important;
}

.crad-title{
    font-size: 23px;
    font-weight: bold;
    color: rgba(22, 47, 92, 0.836) !important;
}
.sertifikatImages{
    width: 80% !important;
}
.sertifikatImages1{
    display: flex;
    justify-content: center !important;
}