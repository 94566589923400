.forHover{
    padding: 0 !important;
    background-image: url("../../assets/firstSanoat.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 350px;
    size: 100%;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;
    transition: .1s linear;
}

.forHover:hover{
    cursor: pointer;
    transform: scale(.9);
    transition: .1s linear;
}
.forHoverDiv{
    display: none;
    transition: .1s linear !important;
}
.forHover:hover .forHoverDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 23px;
    font-weight: 600;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.342);
    position: relative;
    transition: .1s linear !important;
}

/* second */

.forHover2{
    padding: 0 !important;
    background-image: url("../../assets/sanoatSecond.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 350px;
    size: 100%;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;
    transition: .1s linear;
}

.forHover2:hover .forHoverDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 23px;
    font-weight: 600;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.342);
    position: relative;
    transition: .1s linear !important;
}

.forHover2:hover{
    cursor: pointer;
    transform: scale(.9);
    transition: .1s linear;
}

/* third */

.forHover3{
    padding: 0 !important;
    background-image: url("../../assets/thirdSanoat.jpeg");
    background-repeat: no-repeat;
    width: 100%;
    height: 350px;
    size: 100%;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;
    transition: .1s linear;
}

.forHover3:hover .forHoverDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 23px;
    font-weight: 600;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.342);
    position: relative;
    transition: .1s linear !important;
}

.forHover3:hover{
    cursor: pointer;
    transform: scale(.9);
    transition: .1s linear;
}

/* fourth */

.forHover4{
    padding: 0 !important;
    background-image: url("../../assets/fourthSanoat.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 350px;
    size: 100%;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;
    transition: .1s linear;
}

.forHover4:hover .forHoverDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 23px;
    font-weight: 600;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.342);
    position: relative;
    transition: .1s linear !important;
}

.forHover4:hover{
    cursor: pointer;
    transform: scale(.9);
    transition: .1s linear;
}

/* fiveth */

.forHover5{
    padding: 0 !important;
    background-image: url("../../assets/fivesSanoat.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 350px;
    size: 100%;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;
    transition: .1s linear;
}

.forHover5:hover .forHoverDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 23px;
    font-weight: 600;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.342);
    position: relative;
    transition: .1s linear !important;
}

.forHover5:hover{
    cursor: pointer;
    transform: scale(.9);
    transition: .1s linear;
}

/* sixth */

.forHover6{
    padding: 0 !important;
    background-image: url("../../assets/sixthSanoat.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 350px;
    size: 100%;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;
    transition: .1s linear;
}

.forHover6:hover .forHoverDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 23px;
    font-weight: 600;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.342);
    position: relative;
    transition: .1s linear !important;
}

.forHover6:hover{
    cursor: pointer;
    transform: scale(.9);
    transition: .1s linear;
}

/* seven */


.forHover7{
    padding: 0 !important;
    background-image: url("../../assets/seventhSanoatjpg.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 350px;
    size: 100%;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;
    transition: .1s linear;
}

.forHover7:hover .forHoverDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 23px;
    font-weight: 600;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.342);
    position: relative;
    transition: .1s linear !important;
}

.forHover7:hover{
    cursor: pointer;
    transform: scale(.9);
    transition: .1s linear;
}

/* eight */

.forHover8{
    padding: 0 !important;
    background-image: url("../../assets/eightTHSanoatjpg.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 350px;
    size: 100%;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;
    transition: .1s linear;
}

.forHover8:hover .forHoverDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 23px;
    font-weight: 600;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.342);
    position: relative;
    transition: .1s linear !important;
}

.forHover8:hover{
    cursor: pointer;
    transform: scale(.9);
    transition: .1s linear;
}

/* nine */

.forHover9{
    padding: 0 !important;
    background-image: url("../../assets/nightTHSanoat.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 350px;
    size: 100%;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;
    transition: .1s linear;
}

.forHover9:hover .forHoverDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 23px;
    font-weight: 600;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.342);
    position: relative;
    transition: .1s linear !important;
}

.forHover9:hover{
    cursor: pointer;
    transform: scale(.9);
    transition: .1s linear;
}

/* tentth */

.forHover10{
    padding: 0 !important;
    background-image: url("../../assets/tenThSanoat.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 350px;
    size: 100%;
    background-size: cover;
    border-radius: 16px;
    overflow: hidden;
    transition: .1s linear;
}

.forHover10:hover .forHoverDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 23px;
    font-weight: 600;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.342);
    position: relative;
    transition: .1s linear !important;
}

.forHover10:hover{
    cursor: pointer;
    transform: scale(.9);
    transition: .1s linear;
}