body {
    background-color: white;
    font-family: sans-serif !important;
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
    box-sizing: border-box;
    display: flex;
}

.for_logo img {
    margin: 0 10px 0 0;
    width: 100px;
    height: 100px;
}

.for-burger {
    display: none;
}

.for-burger img {
    display: flex;
    justify-content: end;
    width: 50px;
    height: 50px;
}

.menuText {
    text-decoration: none !important;
    padding: 3px;
    margin: 0 10px;
    border-top: 2px solid transparent !important;
    border-bottom: 2px solid transparent !important;
    font-family: sans-serif;
    font-weight: bold !important;
    color: rgba(22, 47, 92, 0.836) !important;
    font-size: 17px !important;
    transition: .5s !important;
}

.menuText:hover {
    border-top: 2px solid rgba(22, 47, 92, 0.836) !important;
    border-bottom: 2px solid rgba(22, 47, 92, 0.836) !important;
    transition: 1.5s !important;
}

.activeText{
    text-decoration: none !important;
    padding: 3px;
    margin: 0 10px;
    border-top: 2px solid transparent !important;
    border-bottom: 2px solid transparent !important;
    font-family: sans-serif;
    font-weight: bold !important;
    color: rgba(22, 47, 92, 0.836) !important;
    font-size: 17px !important;
    transition: .5s !important;
    border-top: 2px solid rgba(22, 47, 92, 0.836) !important;
    border-bottom: 2px solid rgba(22, 47, 92, 0.836) !important;
    transition: 1.5s !important;
}

.navbarbg {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 40px !important;
    position: relative !important;
    z-index: 1;
    box-shadow: -3px 3px 10px -4px black !important;
    background-color: rgba(179, 179, 179, 0.068) !important;
}

.menu-box {
    display: none;
}
.me-auto{
    margin-left: 3%;
}
/* .ReactFlagsSelect-module_label__27pw9{
    display: none;
}
.ReactFlagsSelect-module_selectBtn__19wW7{
    border-radius: 50% !important;
    padding: 0 10px 0 0!important;
    width: 65px !important;
    height: 65px !important;
} */

@media (min-width: 768px) and (max-width: 950px) {
    .activeText{
        font-size: 5px !important;
    }
    .me-auto {
        display: none !important;
    }

    .for-language {
        display: none;
    }

    .for-burger {
        display: flex;
        margin-left: 77%;
        display: block;
    }

    .menu-box {
        width: 100%;
        display: block;
        display: flex;
        justify-content: end;
        position: absolute;
    }

    .menu-box-child {
        overflow: hidden;
        width: 0;
        transition: .3s !important;
        cursor: pointer;
        opacity: 0;
    }

    .menu-box-child-active {
        cursor: pointer;
        transition: .3s !important;
        padding: 10px;
        width: 250px;
        background-color: white;
        position: absolute;
        margin-top: -140px;
        z-index:5;
        height: 100vh !important;
        box-shadow: -3px 3px 10px -4px black !important;
    }
    .me-auto-active{
        margin-top: 30px;
    }
    .me-auto-active div{
        width: 100%;
        height:40px;
    }

    .closeBurgerImg {
        width: 30px;
        height: 30px;
    }
    
}

@media (min-width: 512px) and (max-width: 768px) {
    .me-auto {
        display: none !important;
    }

    .for-language {
        display: none;
    }

    .for-burger {
        display: flex;
        margin-left: 65%;
        display: block;
    }
    .for_logo img {
        margin: 0 10px 0 0;
        width: 100px;
        height: 100px;
    }
    .menu-box {
        width: 100%;
        display: block;
        display: flex;
        justify-content: end;
        position: absolute;
    }

    .menu-box-child {
        overflow: hidden;
        width: 0;
        transition: .3s !important;
        cursor: pointer;
        opacity: 0;
    }

    .menu-box-child-active {
        cursor: pointer;
        transition: .3s !important;
        padding: 10px;
        width: 250px;
        background-color: white;
        position: absolute;
        margin-top: -140px;
        z-index:5;
        height: 100vh !important;
        box-shadow: -3px 3px 10px -4px black !important;
    }
    .me-auto-active{
        margin-top: 30px;
    }
    .me-auto-active div{
        width: 100%;
        height:40px;
    }

    .closeBurgerImg {
        width: 30px;
        height: 30px;
    }
    
}

@media (min-width: 412px) and (max-width: 512px) {
    .me-auto {
        display: none !important;
    }

    .for-language {
        display: none;
    }
    .for_logo img {
        margin: 0 10px 0 0;
        width: 100px;
        height: 100px;
    }
    .for-burger {
        display: flex;
        margin-left: 53%;
        display: block;
    }

    .menu-box {
        width: 100%;
        display: block;
        display: flex;
        justify-content: end;
        position: absolute;
    }

    .menu-box-child {
        overflow: hidden;
        width: 0;
        transition: .3s !important;
        cursor: pointer;
        opacity: 0;
    }

    .menu-box-child-active {
        cursor: pointer;
        transition: .3s !important;
        padding: 10px;
        width: 250px;
        background-color: white;
        position: absolute;
        margin-top: -140px;
        z-index:5;
        height: 100vh !important;
        box-shadow: -3px 3px 10px -4px black !important;
    }
    .me-auto-active{
        margin-top: 30px;
    }
    .me-auto-active div{
        width: 100%;
        height:40px;
    }

    .closeBurgerImg {
        width: 30px;
        height: 30px;
    }
    
}

@media (min-width: 280px) and (max-width: 412px) {
    .for_logo img {
        margin: 0 10px 0 0;
        width: 80px;
        height: 80px;
    }
    .me-auto {
        display: none !important;
    }

    .for-language {
        display: none;
    }

    .for-burger {
        display: flex;
        margin-left: 53%;
        display: block;
    }

    .menu-box {
        width: 100%;
        display: block;
        display: flex;
        justify-content: end;
        position: absolute;
    }

    .menu-box-child {
        overflow: hidden;
        width: 0;
        transition: .3s !important;
        cursor: pointer;
        opacity: 0;
    }

    .menu-box-child-active {
        cursor: pointer;
        transition: .3s !important;
        padding: 10px;
        width: 200px;
        background-color: white;
        position: absolute;
        margin-top: -120px;
        z-index:5;
        height: 100vh !important;
        box-shadow: -3px 3px 10px -4px black !important;
    }
    .activeText{
        font-size: 15px !important;
    }
    .me-auto-active{
        margin-top: 30px;
    }
    .me-auto-active div{
        width: 100%;
        height:40px;
    }

    .closeBurgerImg {
        width: 30px;
        height: 30px;
    }
    
}