.bitrixTitle{
    font-size: 35px;
    font-weight: bold;
}
.bitrixNumber{
    font-size: 35px;
    color: rgba(0, 0, 255, 0.479);
    font-weight: bold;
}
.BitrixDesc{
    margin-top: 10px;
    font-size: 30px;
}

.bitrixTitle2{
    font-size: 30px;
    font-weight: bold;
}