.firstMainText {
    background-image: url("../../assets/3Animation.gif");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center !important;
    background-attachment: fixed;
    background-repeat: no-repeat;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: bold;
    width: 100%;
    height: 80vh;
    position: relative;
    /* color: rgba(22, 47, 92, 0.836) !important; */
    color: white;
}

.in_firstEffect {
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba(12, 11, 11, 0.788);
    border: 3px solid #f1f1f1;
}

.firstMainTextImg {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.howToAvtoText {
    /* border: 1px solid; */
    margin-top: 70px;
    /* width: 100%; */
    box-sizing: border-box;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    color: rgba(22, 47, 92, 0.836) !important;
}

.howToAvtoTextChild {
    font-size: 60px;
    color: rgba(22, 47, 92, 0.836) !important;
}

.titleStatistik {
    width: 100%;
    display: flex;
    justify-content: center !important;
    font-size: 25px;
    font-weight: bold;
    color: rgba(22, 47, 92, 0.836) !important;
}

.inText {
    width: 60%;
}

.childTex {
    display: flex;
    justify-content: center;
    font-size: 22px;
    opacity: .7;
}

.childTextIn {
    width: 60%;
}

.forBachImgFather {
    padding: 0 !important;
}

.forBacground {
    background: rgba(black);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 70vh;
    margin: 0 !important;
    background-image: url("../../assets/bacGcrount.jfif");
    background-repeat: no-repeat;
    background-size: cover;
}
.forBacground2 {
    background: rgba(black);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 70vh;
    margin: 0 !important;
    background-image: url("../../assets/6.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
.forBacground3 {
    background: rgba(black);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 70vh;
    margin: 0 !important;
    background-image: url("../../assets/7.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
.forBacground4 {
    background: rgba(black);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 70vh;
    margin: 0 !important;
    background-image: url("../../assets/roznitsABackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
.forBacground6 {
    background: rgba(black);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 70vh;
    margin: 0 !important;
    background-image: url("../../assets/DocumentAbarot.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
.forBacground5 {
    background: rgba(black);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 70vh;
    margin: 0 !important;
    background-image: url("../../assets/roznitsABackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.ONEcBugalter {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    font-weight: bold;
    border: 3px solid #f1f1f1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center !important;
    height: 85%;
}
.ONEcBugalterIN{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.oneCAccountingText{
    font-size: 60px;
    text-align: center !important;
}
.oneCAccountingText2{
    font-size: 30px;
}

@media (min-width: 992px) and (max-width: 1280px) {
    .firstMainText {
        position: relative;
        width: 100% !important;
        padding: 0 !important;
        background-size: cover;
        color: white;
        height: 70vh !important;
        margin-top: -200px;
    }
    
    .in_firstEffect {
        width: 50%;
        height: 50%;
        display: flex;
        font-size: 30px !important;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(12, 11, 11, 0.788);
        border: 3px solid #f1f1f1;
    }
    .howToAvtoText {
        box-sizing: border-box;
        text-align: center;
        font-size: 30px;
        font-weight: bold;
        color: rgba(22, 47, 92, 0.836) !important;
    }
    .titleStatistik {
        width: 100%;
        display: flex;
        justify-content: center !important;
        font-size: 20px;
        font-weight: bold;
        color: rgba(22, 47, 92, 0.836) !important;
    }
    .childTex {
        display: flex;
        justify-content: center;
        font-size: 18px;
        opacity: .7;
    }
    .oneCAccountingText{
        font-size: 43px;
    }
    .oneCAccountingText2{
        font-size: 23px;
    }
}
@media (min-width: 776px) and (max-width: 992px) {
    .firstMainText {
        position: relative;
        width: 100% !important;
        padding: 0 !important;
        background-size: cover;
        color: white;
        height: 70vh !important;
        margin-top: -200px;
    }
    
    .in_firstEffect {
        width: 70%;
        height: 50%;
        display: flex;
        font-size: 30px !important;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(12, 11, 11, 0.788);
        border: 3px solid #f1f1f1;
    }
    .howToAvtoText {
        box-sizing: border-box;
        text-align: center;
        font-size: 30px;
        font-weight: bold;
        color: rgba(22, 47, 92, 0.836) !important;
    }
    .titleStatistik {
        width: 100%;
        display: flex;
        justify-content: center !important;
        font-size: 20px;
        font-weight: bold;
        color: rgba(22, 47, 92, 0.836) !important;
    }
    .childTex {
        display: flex;
        justify-content: center;
        font-size: 18px;
        opacity: .7;
    }
    .oneCAccountingText{
        font-size: 38px;
    }
    .oneCAccountingText2{
        font-size: 20px;
    }
}
@media (min-width: 512px) and (max-width: 776px) {
    .firstMainText {
        position: relative;
        width: 100% !important;
        padding: 0 !important;
        background-size: cover;
        color: white;
        height: 70vh !important;
        margin-top: -200px;
    }
    
    .in_firstEffect {
        width: 70%;
        height: 50%;
        display: flex;
        font-size: 30px !important;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(12, 11, 11, 0.788);
        border: 3px solid #f1f1f1;
    }
    .howToAvtoText {
        box-sizing: border-box;
        text-align: center;
        font-size: 30px;
        font-weight: bold;
        color: rgba(22, 47, 92, 0.836) !important;
    }
    .titleStatistik {
        width: 100%;
        display: flex;
        justify-content: center !important;
        font-size: 20px;
        font-weight: bold;
        color: rgba(22, 47, 92, 0.836) !important;
    }
    .childTex {
        display: flex;
        justify-content: center;
        font-size: 18px;
        opacity: .7;
    }
    .oneCAccountingText{
        font-size: 25px;
    }
    .oneCAccountingText2{
        font-size: 16px;
    }
    .ONEcBugalter{
        width: 80%;
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        align-content: space-around;
    }
    .ONEcBugalter img{
        width: 50%;
    }

}
@media (min-width: 412px) and (max-width: 512px) {
    .firstMainText {
        position: relative;
        width: 100% !important;
        padding: 0 !important;
        background-size: cover;
        color: white;
        height: 70vh !important;
        margin-top: -200px;
    }
    
    .in_firstEffect {
        width: 80%;
        height: 50%;
        display: flex;
        font-size: 30px !important;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(12, 11, 11, 0.788);
        border: 3px solid #f1f1f1;
    }
    .howToAvtoText {
        box-sizing: border-box;
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        color: rgba(22, 47, 92, 0.836) !important;
    }
    .titleStatistik {
        width: 100%;
        display: flex;
        justify-content: center !important;
        font-size: 20px;
        font-weight: bold;
        color: rgba(22, 47, 92, 0.836) !important;
    }
    .childTex {
        display: flex;
        justify-content: center;
        font-size: 18px;
        opacity: .7;
    }
    .oneCAccountingText{
        font-size: 25px;
    }
    .oneCAccountingText2{
        font-size: 16px;
    }
    .ONEcBugalter{
        width: 80%;
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        align-content: space-around;
    }
    .ONEcBugalter img{
        width: 50%;
    }

}
@media (min-width: 280px) and (max-width: 412px) {
    .firstMainText {
        position: relative;
        width: 100% !important;
        padding: 0 !important;
        background-size: cover;
        color: white;
        height: 40vh !important;
        margin-top: -200px;
    }
    
    .in_firstEffect {
        width: 80%;
        height: 50%;
        display: flex;
        font-size: 20px !important;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(12, 11, 11, 0.788);
        border: 3px solid #f1f1f1;
    }
    .howToAvtoText {
        box-sizing: border-box;
        text-align: center;
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold;
        color: rgba(22, 47, 92, 0.836) !important;
    }
    .titleStatistik {
        width: 100%;
        display: flex;
        justify-content: center !important;
        font-size: 20px;
        font-weight: bold;
        color: rgba(22, 47, 92, 0.836) !important;
    }
    .childTex {
        display: flex;
        justify-content: center;
        font-size: 18px;
        opacity: .7;
    }
    .oneCAccountingText{
        font-size: 25px;
    }
    .oneCAccountingText2{
        font-size: 16px;
    }
    .ONEcBugalter{
        width: 80%;
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        align-content: space-around;
    }
    .ONEcBugalter img{
        width: 50%;
    }

}