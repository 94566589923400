.biznesAvtomat{
    padding: 2%;
    /* width: 80% !important; */
    /* background-color: #1d1a37 !important;
    margin: 20px;
    color: white !important; */
}
.biznesAvtomat .card{
    background-color: #1d1a37 !important;
    padding:5% 10%;
    color: white !important;
    height: 100%;
    font-family: Montserrat;
    font-size: 21px;
}
.textNumber{
    font-family: Montserrat;
    font-size: 21px;
}

.for_i i{
    color: rgba(22, 47, 92, 0.836);
    font-size: 30px;
    font-weight: bold;
}
.for_icon_biznes{
    color: rgba(22, 47, 92, 0.836);
    font-size: 35px;
    font-weight: bold;
}

@media (min-width: 280px) and (max-width: 412px){
    .textNumber{
        font-family: Montserrat;
        font-size: 21px;
    }
    .for_i i{
        color: rgba(22, 47, 92, 0.836);
        font-size: 18px;
        font-weight: bold;
    }
    .for_icon_biznes{
        color: rgba(22, 47, 92, 0.836);
        font-size: 22px;
        font-weight: bold;
    }
}