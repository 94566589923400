.forBacgroundOur {
    background-image: url("../../assets/ourServices.jpeg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center !important;
    background-attachment: fixed;
    background-repeat: no-repeat;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: bold;
    width: 100%;
    height: 80vh;
    position: relative;
    /* color: rgba(22, 47, 92, 0.836) !important; */
    color: white;
}
.card_titlerOur{
    color: rgba(22, 47, 92, 0.836) !important;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
}
.forOurBizines{
    padding: 20px 25px;
    box-sizing: border-box;
    /* margin:10px 0; */
    height: 100% !important;
}
.more button{
    background-color: rgba(31, 68, 138, 0.836) !important;
    color: white !important;
    padding: 10px 15px;
    border-radius: 8px;
    outline: none;
    border: none;
    transition: .1s linear;
}
.more button:hover{
    background-color: rgba(77, 120, 201, 0.836) !important;
    color: white !important;
    transition: .1s linear;
    transform: scale(.9);
    padding: 10px 15px;
    border-radius: 8px;
    outline: none;
    border: none;
}

@media (min-width: 280px) and (max-width: 412px) {
    .forBacgroundOur {
        background-image: url("../../assets/ourServices.jpeg");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center center !important;
        background-attachment: fixed;
        background-repeat: no-repeat;
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: bold;
        width: 100%;
        height: 30vh;
        position: relative;
        margin-top: -20px !important;
        color: white;
    }
    .card_titlerOur{
        color: rgba(22, 47, 92, 0.836) !important;
        font-size: 22px;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
    }
}