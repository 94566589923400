.for_contact_icon {
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
    padding: 20px;
    margin-bottom: 15px;
    box-sizing: border-box;
}

.for-phone {
    font-size: 22px;
    opacity: .6;
}

.anticon {
    /* text-align: center; */
    /* align-content: center !important; */
    font-size: 60px;
    opacity: .6;
    /* justify-content: center !important; */
}

.contactImg {
    height: 98% !important;
    width: 100% !important;
    margin-top: -25px;
}

.for_Soccial {
    /* opacity: .6; */
    text-decoration: none !important;
    color: black !important;
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .for_contact_icon {
        padding: 8.5px;
        margin-bottom: 10px;
        box-sizing: border-box;
    }

    .contactImg {
        height: 100% !important;
        width: 100%;
        margin-top: -25px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .for_contact_icon {
        padding: 5px;
        margin-bottom: 10px;
        box-sizing: border-box;
    }

    .contactImg {
        height: 101.1% !important;
        width: 100%;
        margin-top: -18px;
    }

    .for-phone {
        font-size: 20px;
        opacity: .6;
    }
    
    .anticon {
        /* text-align: center; */
        /* align-content: center !important; */
        font-size: 50px;
        opacity: .6;
        /* justify-content: center !important; */
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .for_contact_icon {
        padding: 5px;
        margin-bottom: 10px;
        box-sizing: border-box;
    }

    .contactImg {
        height: 101.1% !important;
        width: 100%;
        margin-top: -18px;
    }

    .for-phone {
        font-size: 20px;
        opacity: .6;
    }
    
    .anticon {
        /* text-align: center; */
        /* align-content: center !important; */
        font-size: 50px;
        opacity: .6;
        /* justify-content: center !important; */
    }
}

@media (min-width: 280px) and (max-width: 412px) {
    .for_contact_icon {
        padding: 5px;
        margin-bottom: 10px;
        box-sizing: border-box;
    }

    .contactImg {
        height: 101.1% !important;
        width: 100%;
        margin-top: 10px;
    }

    .for-phone {
        font-size: 20px;
        opacity: .6;
    }
    
    .anticon {
        /* text-align: center; */
        /* align-content: center !important; */
        font-size: 50px;
        opacity: .6;
        /* justify-content: center !important; */
    }
}